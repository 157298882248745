import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import MentionTextarea from './MentionTextarea';
import Feed from './Feed';

import { getProjectMessages } from '../selectors';
import { closePopup, createSelectFilePopup } from '../actions/popup';
import { createAddMessage } from '../actions/message';
import { createDeleteTemp } from '../actions/temp';

import { BASE_ZINDEX } from '../popup-factory';

import { updateNotesFeed } from '../actions/message';

const TEMP_FILE_REGISTER = 'note_file_id';

class NotePopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      files: props.files,
      publisher_text_value: '',
      po_notes: this.getPoNotes(props),
      feed_loading: true
    };

    this.handleAddMessage = this.handleAddMessage.bind(this);
    this.renderPreview = this.renderPreview.bind(this);
    this.handlePublisherText = this.handlePublisherText.bind(this);
  }

  componentDidMount() {
    this.props.onNotesFeed(this.props.project.job_id);
  }

  componentWillUnmount(){
    this.props.onCreateDeleteTemp(TEMP_FILE_REGISTER);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      feed_loading: false,
      po_notes: this.getPoNotes(nextProps)
    });
    if(nextProps.files.length) {
      this.setState({ files: nextProps.files });
    }
  }

  getPoNotes(props) {
    const {
      messages, purchase_order_id,
    } = props;

    return _.filter(messages || [], {
      details_parent_type: 'PURCHASE ORDER',
      details_parent_id: purchase_order_id,
    });
  }

  renderPreview(files) {
    this.setState({
      files: files
    });
  }

  handleAddMessage(job_id, publisher_text, files, details_parent_id) {
    let patt = /(@[A-z0-9]+)/g;
    let user_ids_arr = [];
    let m;
    do {
      m = patt.exec(publisher_text);
      if(m && this.props.mention_users.filter(u => u.mask == m[0].slice(1)).length != 0) {
        user_ids_arr.push(this.props.mention_users.filter(u => u.mask == m[0].slice(1))[0]['user_id']);
      }
    } while(m);
    let tagged_users = user_ids_arr.toString();
    const file_ids = files.map(f => f.file_id);
    this.props.onAddMessage(job_id, publisher_text, null, null, file_ids, null, details_parent_id, 'PURCHASE ORDER');
    this.props.onCreateDeleteTemp(TEMP_FILE_REGISTER);
    this.setState(Object.assign({}, this.state, { files: [] }));
    this.setState({ publisher_text_value: '' });
  }

  handlePublisherText(value) {
    this.setState({ publisher_text_value: value });
  }

  renderLoading() {
    return (
      <div className='row popup-content column'>
          <div className='small-12 columns'>
              <div className='small-12 text-center'><br /><img src='/images/gears.gif' /><br /></div>
          </div>
      </div>
    );
  }

  render() {
    const {
      purchase_order_id, project, mention_users, loading, onClosePopup, index, onNotesFeed, onCreateSelectFilePopup
    } = this.props;
    const {
      files, po_notes, feed_loading
    } = this.state;
    const text_value = this.state.publisher_text_value;
    const job_id = project.job_id;

    return (
      <div id="replace-header-modal" className="reveal large" style={{display: 'block', zIndex: BASE_ZINDEX + index}} aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3 id="modalTitle">Notes</h3>
          </div>
        </div>
        <div className="row popup-content">
          <div className="row publisher">
            <div className="medium-12 columns">
              <MentionTextarea value={text_value} mention_users={mention_users}
                placeholder="Post a note about this PO"
                onChange={this.handlePublisherText}/>
              <div>
                {files[0] ?
                  `${files[0].file_display_name} is selected.`
                : null}
              </div>
              <div className="medium-4">
                {loading ?
                  <div className="text-center"><br /><img src="/images/gears.gif" /><br /></div>
                : null}
              </div>
            </div>
            <div className="large-8 columns">&nbsp;</div>
            <div className="large-2 columns">
              <a className="button" onClick={e => {e.preventDefault(); onCreateSelectFilePopup(TEMP_FILE_REGISTER, job_id, 'JOB', project.account_id);}}>
                <i className="fi-paperclip">&nbsp;&nbsp;</i> File
              </a>
            </div>
            <div className="large-2 columns">
              <a className="button alt" onClick={e => { e.preventDefault(); this.handleAddMessage(job_id, text_value, files, purchase_order_id); }} disabled={text_value != '' ? false : true}>Add Note</a>
            </div>
          </div>
          <div className="medium-12 columns">
            {feed_loading ?
              this.renderLoading()
            :
              <Feed posts={po_notes} job_id={job_id} comment_allowed={true} />
            }
          </div>
        </div>
        <button className="close-button" aria-label="Close modal" type="button" onClick={e => {e.preventDefault(); onClosePopup();}}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    messages: getProjectMessages(state),
    mention_users: Object.values(state.entities.users).filter(u => u.mask != false),
    files: state.temp[TEMP_FILE_REGISTER] ? [state.entities.files[state.temp[TEMP_FILE_REGISTER]]] : []
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onAddMessage: (job_id, text, reminder_user_id, reminder_date, files, tagged_users, details_parent_id, details_parent_type) => {
      dispatch(createAddMessage(job_id, text, reminder_user_id, reminder_date, files, tagged_users, details_parent_id, details_parent_type));
    },
    onNotesFeed: (job_id) => dispatch(updateNotesFeed(job_id)),
    onClosePopup: () => {
      dispatch(closePopup());
    },
    onCreateSelectFilePopup: (register, parent_id, parent_type, client_id) => {
      dispatch(createSelectFilePopup(register, parent_id, parent_type, client_id));
    },
    onCreateDeleteTemp: key => {
      dispatch(createDeleteTemp(key));
    },
  };
};

const ConnectedNotePopup = connect(mapStateToProps, mapDispatchToProps)(NotePopup);
export default ConnectedNotePopup;
